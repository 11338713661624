import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { listUsertables } from '../graphql/queries';
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export const SearchAliasBox = () => {
  const [searchedAlias, setSearchedAlias] = React.useState('');
  const [searchedAliasOptions, setSearchedAliasOptions] = React.useState([]);
  const [searchedAliasNameOptions, setSearchedAliasNameOptions] = React.useState([]);

  const getfilteredAliasOptions = async (newAlias) => {
    const filter = { common_name: { contains: newAlias } };
    const result = await API.graphql({
      query: listUsertables,
      variables: { filter: filter, limit: 10 },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    const resultitems = result.data.listUsertables.items;
    const searchedaliasoptions = [];
    const searchedaliasnameoptions = [];
    for (let i = 0; i < resultitems.length; i++) {
      searchedaliasoptions.push(resultitems[i].id);
      searchedaliasnameoptions.push(resultitems[i].common_name);
    }
    setSearchedAliasOptions(searchedaliasoptions);
    setSearchedAliasNameOptions(searchedaliasnameoptions);
  };

  const getAliasFromCommonName = (commonName) => {
    const front = commonName.indexOf('(');
    const back = commonName.indexOf(')');
    return commonName.slice(front + 1, back);
  };

  return (
    <Autocomplete
      value={searchedAlias}
      disablePortal
      disableClearable
      id="header-search"
      options={searchedAliasNameOptions} //表示するリスト
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Link to={`/my-page/${getAliasFromCommonName(option)}`} key={getAliasFromCommonName(option)}>
            {option}
          </Link>
        </li>
      )}
      onInputChange={(event, newAlias) => {
        setSearchedAlias(newAlias);
        if (newAlias.length >= 3) {
          getfilteredAliasOptions(newAlias);
        } else {
          setSearchedAliasOptions([]);
        }
      }}
      sx={{ width: 150 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <Stack direction="row" spacing={0}>
              <SearchIcon />
              <Typography component="div">Search</Typography>
            </Stack>
          }
        />
      )}
    />
  );
};

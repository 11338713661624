import { createContext, useState } from 'react';

export const TalkStatusContext = createContext({});

export const TalkStatusProvider = (props) => {
    const { children } = props;
    const [freetotalkselected, setFreeToTalkSelected] = useState(false);

    return <TalkStatusContext.Provider value={{ freetotalkselected, setFreeToTalkSelected }}>{children}</TalkStatusContext.Provider>;
};

import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import mypage from './mypage';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [dashboard, pages, utilities, other]
  items: [dashboard, other, mypage]
};

export default menuItems;

import { createContext, useState, useCallback } from 'react';

export const OfficeInfoContext = createContext({});

export const OfficeInfoProvider = (props) => {
    const { children } = props;
    const blank = '';

    const [onsiteOffice, setOffice] = useState(blank);
    const [onsiteFloor, setFloor] = useState(blank);
    const [onsiteSeat, setSeat] = useState(blank);

    return <OfficeInfoContext.Provider value={{ onsiteOffice, setOffice, onsiteFloor, setFloor, onsiteSeat, setSeat }}>{children}</OfficeInfoContext.Provider>;
};

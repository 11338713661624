import { createContext, useState, useCallback } from 'react';

export const EventListContext = createContext({});

export const EventListProvider = (props) => {
    const { children } = props;

    const [posts, updatePosts] = useState([]);
    const [updateTime, setUpdateTime] = useState()

    // delete post
    const removePost = useCallback(
        (index) => {
            const newPosts = [...posts];
            newPosts.splice(index, 1);
            updatePosts(newPosts);
        },
        [posts]
    );

    return <EventListContext.Provider value={{ posts, updatePosts, removePost, updateTime, setUpdateTime }}>{children}</EventListContext.Provider>;
};

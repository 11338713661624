import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TagFacesOutlinedIcon from '@mui/icons-material/TagFacesOutlined';
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone';
import { FullscreenExit, Launch } from '@mui/icons-material';
import { purple } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const TalkChip = (props) => {
  const { status_flag } = props; //Boolean //hoge
  if (status_flag) {
    return <Chip icon={<TagFacesOutlinedIcon />} color="success" size="small" label="Free to Talk" />;
  } else {
    return <Chip icon={<DoNotDisturbOnTwoToneIcon />} label="Busy" size="small" variant="outlined" />;
  }
};
export default TalkChip;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      name
      location
      description
      image
      owner
      photos
      participants
      date
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      name
      location
      description
      image
      owner
      photos
      participants
      date
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      name
      location
      description
      image
      owner
      photos
      participants
      date
      createdAt
      updatedAt
    }
  }
`;
export const createUserdata = /* GraphQL */ `
  mutation CreateUserdata(
    $input: CreateUsertableInput!
    $condition: ModelUsertableConditionInput
  ) {
    createUserdata(input: $input, condition: $condition) {
      id
      email
      event_id_history
      status_free_to_talk
      status_lunch
      status_after_work
      welcome_topic
      common_name
      unwelcome_topic
      user_icon_url
      onsite_office
      onsite_floor
      onsite_office_floor
      onsite_seat
      family_name
      department
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserdata = /* GraphQL */ `
  mutation UpdateUserdata(
    $input: UpdateUsertableInput!
    $condition: ModelUsertableConditionInput
  ) {
    UpdateUserdata(input: $input, condition: $condition) {
      id
      email
      event_id_history
      status_free_to_talk
      status_lunch
      status_after_work
      welcome_topic
      common_name
      unwelcome_topic
      user_icon_url
      onsite_office
      onsite_floor
      onsite_office_floor
      onsite_seat
      family_name
      department
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserdata = /* GraphQL */ `
  mutation DeleteUserdata(
    $input: DeleteUsertableInput!
    $condition: ModelUsertableConditionInput
  ) {
    deleteUserdata(input: $input, condition: $condition) {
      id
      email
      event_id_history
      status_free_to_talk
      status_lunch
      status_after_work
      welcome_topic
      common_name
      unwelcome_topic
      user_icon_url
      onsite_office
      onsite_floor
      onsite_office_floor
      onsite_seat
      family_name
      department
      createdAt
      updatedAt
      owner
    }
  }
`;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      name
      location
      description
      image
      owner
      photos
      participants
      date
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        location
        description
        image
        owner
        photos
        participants
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsertable = /* GraphQL */ `
  query GetUsertable($id: ID!) {
    getUsertable(id: $id) {
      id
      email
      event_id_history
      status_free_to_talk
      status_lunch
      status_after_work
      welcome_topic
      common_name
      unwelcome_topic
      user_icon_url
      onsite_office
      onsite_floor
      onsite_office_floor
      onsite_seat
      family_name
      department
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsertables = /* GraphQL */ `
  query ListUsertables(
    $filter: ModelUsertableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsertables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        event_id_history
        status_free_to_talk
        status_lunch
        status_after_work
        welcome_topic
        common_name
        unwelcome_topic
        user_icon_url
        onsite_office
        onsite_floor
        onsite_office_floor
        onsite_seat
        family_name
        department
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listLunchBySpecificDepartment = /* GraphQL */ `
  query ListLunchBySpecificDepartment(
    $status_lunch: Int!
    $department: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsertableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLunchBySpecificDepartment(
      status_lunch: $status_lunch
      department: $department
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        event_id_history
        status_free_to_talk
        status_lunch
        status_after_work
        welcome_topic
        common_name
        unwelcome_topic
        user_icon_url
        onsite_office
        onsite_floor
        onsite_office_floor
        onsite_seat
        family_name
        department
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listAfterWorkBySpecificDepartment = /* GraphQL */ `
  query ListAfterWorkBySpecificDepartment(
    $status_after_work: String!
    $department: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsertableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAfterWorkBySpecificDepartment(
      status_after_work: $status_after_work
      department: $department
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        event_id_history
        status_free_to_talk
        status_lunch
        status_after_work
        welcome_topic
        common_name
        unwelcome_topic
        user_icon_url
        onsite_office
        onsite_floor
        onsite_office_floor
        onsite_seat
        family_name
        department
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listOnsiteOfficeFloor = /* GraphQL */ `
  query ListOnsiteOfficeFloor(
    $onsite_office_floor: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUsertableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnsiteOfficeFloor(
      onsite_office_floor: $onsite_office_floor
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        event_id_history
        status_free_to_talk
        status_lunch
        status_after_work
        welcome_topic
        common_name
        unwelcome_topic
        user_icon_url
        onsite_office
        onsite_floor
        onsite_office_floor
        onsite_seat
        family_name
        department
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listDepartmentBySpecificOnsiteSeat = /* GraphQL */ `
  query ListDepartmentBySpecificOnsiteSeat(
    $department: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUsertableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartmentBySpecificOnsiteSeat(
      department: $department
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        event_id_history
        status_free_to_talk
        status_lunch
        status_after_work
        welcome_topic
        common_name
        unwelcome_topic
        user_icon_url
        onsite_office
        onsite_floor
        onsite_office_floor
        onsite_seat
        family_name
        department
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://c6a7me4lcnd7hozyqc65a4ecn4.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rxcljpnpafhwhbu43yvsmz3pfi",
    "aws_cloud_logic_custom": [
        {
            "name": "getcookie",
            "endpoint": "https://j6b9yeruy7.execute-api.ap-northeast-1.amazonaws.com/tokyotest",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:a45f7231-713c-4f92-84b8-6af673dffef5",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_ZL4Vutoy8",
    "aws_user_pools_web_client_id": "78i9heaku0duc52ajce1rptb73",
    "oauth": {
        "domain": "motnetc52b83f8-c52b83f8-tokyotest.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://export-environment.d1abdr0aygk7vm.amplifyapp.com/,http://localhost:3000/,https://motnet.kamiyatt.people.aws.dev/",
        "redirectSignOut": "https://export-environment.d1abdr0aygk7vm.amplifyapp.com/,http://localhost:3000/,https://export-environment.d1abdr0aygk7vm.amplifyapp.com/,https://motnet.kamiyatt.people.aws.dev/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "motnet922973feec9242ef82fef08c1b68840b194151-tokyotest",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;

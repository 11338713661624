import * as React from 'react';
import { Button } from '@mui/material';
import { Auth } from 'aws-amplify';
import { post } from '../network/hooks/restApi';

export const FollowButton = (props) => {
  const [isfollowSelected, setIsfollowSelected] = React.useState(false);
  const [initializeFlag, setinitializeFlag] = React.useState(true);
  const [alias, setAlias] = React.useState();

  const createIsfollowfunc_button = async (status) => {
    // const res = await API.graphql({
    //   query: updateUserdata,
    //   variables: {
    //     input: {
    //       id: alias,
    //       status_free_to_talk: status
    //     }
    //   },
    //   authMode: 'AMAZON_COGNITO_USER_POOLS'
    // }); // updated
  };

  React.useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then((user) => {
      setTimeout(() => {
        const midway_s = new String(user.username);
        const newAlias = midway_s.substr(midway_s.indexOf('_') + 1);
        setAlias(newAlias);
        getInitialIsfollow(newAlias);
        setinitializeFlag(false);
        console.log('initial follow');
      }, 1000);
    });
  }, [props]);

  const getInitialIsfollow = async (newAlias) => {
    // const userData = await API.graphql({
    //   query: getUsertable,
    //   variables: { id: newAlias }
    // });
    setIsfollowSelected(false);
    if (props.othersAlias) {
      const isFollowing = await post('follow-get', { alias: newAlias, to: props.othersAlias }, 'test');
      setIsfollowSelected(isFollowing.result);
    }

    // const { status_free_to_talk } = true;
    // if (status_free_to_talk != undefined) {
    //   setIsfollowSelected(status_free_to_talk);
    // }
  };

  const handleChange_button = (flag) => {
    if (flag) {
      post('follow-post', { alias, to: props.othersAlias }, 'test')
        .then((result) => {
          setIsfollowSelected(true);
        })
        .catch((result) => {});
    } else {
      post('follow-delete', { alias, to: props.othersAlias }, 'test')
        .then((result) => {
          setIsfollowSelected(false);
        })
        .catch((result) => {});
    }
    createIsfollowfunc_button(flag);
  };
  return (
    <>
      {(() => {
        if (props.others == true) {
          if (isfollowSelected == true) {
            return (
              <Button
                variant="outlined"
                size="small"
                style={{ maxWidth: '180px', maxHeight: '20px', minWidth: '180px', minHeight: '20px' }}
                //   disabled={initializeFlag}
                onClick={() => {
                  handleChange_button(false);
                }}
              >
                Following
              </Button>
            );
          } else {
            return (
              <Button
                size="small"
                variant="contained"
                style={{ maxWidth: '180px', maxHeight: '20px', minWidth: '180px', minHeight: '20px' }}
                //   disabled={initializeFlag}
                onClick={() => {
                  handleChange_button(true);
                }}
              >
                Follow
              </Button>
            );
          }
        }
      })()}
    </>
  );
};

// assets
import { IconBrandChrome, IconHelp, IconSearch, IconConfetti, IconShare, IconSettings } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp, IconSearch, IconConfetti, IconShare, IconSettings };
const mypage = {
  id: 'mypage-set',
  title: 'Setting',
  type: 'group',
  children: [
    /*
        {
            id: 'sample-page',
            title: 'Sample Page',
            type: 'item',
            url: '/sample-page',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        */
    // sgswyuta add find page
    {
      id: 'my-page',
      title: 'マイページ',
      type: 'item',
      url: '/my-page',
      icon: icons.IconSettings,
      breadcrumbs: false
    }
  ]
};

export default mypage;

import * as React from 'react';
import { ToggleButton, ToggleButtonGroup, Button, Card, Grid, Stack } from '@mui/material';
import { updateUserdata } from '../graphql/mutations';
import { API } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { getUsertable } from 'graphql/queries';
import { listUsertables } from '../graphql/queries';
import TagFacesOutlinedIcon from '@mui/icons-material/TagFacesOutlined';
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone';
import { TalkStatusContext } from 'components/provider/TalkStatusProvider';

export const FreeToTalk = () => {
  const [alias, setAlias] = React.useState();
  const [freetotalk, setFreeToTalk] = React.useState('freetotalk');
  // const [freetotalkselected, setFreeToTalkSelected] = React.useState(false);
  const { freetotalkselected, setFreeToTalkSelected } = React.useContext(TalkStatusContext);
  const [initializeFlag, setinitializeFlag] = React.useState(true);

  const createStatusfunc_button = async (status) => {
    const res = await API.graphql({
      query: updateUserdata,
      variables: {
        input: {
          id: alias,
          status_free_to_talk: status
        }
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }); // updated
  };

  React.useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then((user) => {
      const midway_s = new String(user.username);
      const newAlias = midway_s.substr(midway_s.indexOf('_') + 1);
      setAlias(newAlias);
      getInitialFreeToTalk(newAlias);
    });
  }, []);

  const getInitialFreeToTalk = async (newAlias) => {
    const userData = await API.graphql({
      query: getUsertable,
      variables: { id: newAlias }
    });
    const { status_free_to_talk } = userData.data.getUsertable;
    if (status_free_to_talk != undefined) {
      setFreeToTalk(status_free_to_talk ? 'freetotalk' : null);
      setFreeToTalkSelected(status_free_to_talk);
    }
    setinitializeFlag(false);
  };

  const handleChange_button = (flag) => {
    if (flag == true) {
      setFreeToTalkSelected(true);
    } else {
      setFreeToTalkSelected(false);
    }
    createStatusfunc_button(flag);
  };
  return (
    <>
      {(() => {
        if (freetotalkselected == true) {
          return (
            <Card variant="outlined">
              <Stack spacing={0}>
                <Grid container spacing={0} alignItems="center" justify="center">
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    今話していい？
                  </Grid>
                </Grid>
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  style={{ maxWidth: '130px', maxHeight: '40px', minWidth: '130px', minHeight: '40px' }}
                  disabled={initializeFlag}
                  onClick={() => {
                    handleChange_button(false);
                  }}
                >
                  <TagFacesOutlinedIcon />
                  Free to Talk
                </Button>
              </Stack>
            </Card>
          );
        } else {
          return (
            <Card variant="outlined">
              <Stack spacing={0}>
                <Grid container spacing={0} alignItems="center" justify="center">
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    今話していい？
                  </Grid>
                </Grid>
                <Button
                  size="small"
                  // variant="outlined"
                  color="inherit"
                  style={{ maxWidth: '130px', maxHeight: '40px', minWidth: '130px', minHeight: '40px' }}
                  disabled={initializeFlag}
                  onClick={() => {
                    handleChange_button(true);
                  }}
                >
                  <DoNotDisturbOnTwoToneIcon />
                  Busy
                </Button>
              </Stack>
            </Card>
          );
        }
      })()}
    </>
  );

  // return (
  //   <ToggleButtonGroup color="primary" value={freetotalk} exclusive onChange={handleChange} aria-label="Platform">
  //     <ToggleButton value="freetotalk" selected={freetotalkselected}>
  //       Free to Talk
  //     </ToggleButton>
  //   </ToggleButtonGroup>
  // );
};

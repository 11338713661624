// assets
import { IconBrandChrome, IconHelp, IconMapSearch, IconConfetti, IconShare } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp, IconMapSearch, IconConfetti, IconShare };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'sample-docs-roadmap',
  title: 'Action',
  type: 'group',
  children: [
    /*
        {
            id: 'sample-page',
            title: 'Sample Page',
            type: 'item',
            url: '/sample-page',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        */
    // sgswyuta add find page
    {
      id: 'find-page',
      title: '社員マップ',
      type: 'item',
      url: '/find-page',
      icon: icons.IconMapSearch,
      breadcrumbs: false
    },
    // sgswyuta add event page
    {
      id: 'event-page',
      title: 'イベント',
      type: 'item',
      url: '/event-page',
      icon: icons.IconConfetti,
      breadcrumbs: false
    },
    // sgswyuta add event page
    {
      id: 'network-page',
      title: '社員のつながり',
      type: 'item',
      url: '/network-page',
      icon: icons.IconShare,
      breadcrumbs: false
    }
    // {
    //   id: 'documentation',
    //   title: 'Documentation',
    //   type: 'item',
    //   url: 'https://codedthemes.gitbook.io/berry/',
    //   icon: icons.IconHelp,
    //   external: true,
    //   target: true
    // }
  ]
};

export default other;

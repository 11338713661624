import { createContext, useState } from 'react';

export const LunchInfoContext = createContext({});

export const LunchInfoProvider = (props) => {
  const { children } = props;
  const [lunchSelected, setLunchSelected] = useState(false);

  return <LunchInfoContext.Provider value={{ lunchSelected, setLunchSelected }}>{children}</LunchInfoContext.Provider>;
};

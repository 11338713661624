import { useSelector } from 'react-redux';

import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Amplify, Auth, Hub, AWSCloudWatchProvider } from 'aws-amplify';
import { SelectedInfoProvider } from 'components/provider/SelectedInfoProvider';
import { EventListProvider } from 'components/provider/EventListProvider';
import { OfficeInfoProvider } from 'components/provider/OfficeInfoProvider';
import { LunchInfoProvider } from 'components/provider/LunchInfoProvider';
import { TalkStatusProvider } from 'components/provider/TalkStatusProvider';
import { WelcomTopicProvider } from 'components/provider/WelcomTopicProvider';
import 'react-medium-image-zoom/dist/styles.css';


// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// sgswyuta add amiplify-auth
// ==============================|| AWS Cognito Authentication ||============================== //
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { UserInfoContext } from './components/provider/UserInfoProvider';
import { useContext } from 'react';

const { host } = window.location;

// Fix issues with multiple redirect urls.
// Try to figure out which one to use...
if (awsconfig.oauth.redirectSignIn.includes(',')) {
  const filterHost = (url) => new URL(url).host === host;
  awsconfig.oauth.redirectSignIn = awsconfig.oauth.redirectSignIn.split(',').filter(filterHost).shift();
  awsconfig.oauth.redirectSignOut = awsconfig.oauth.redirectSignOut.split(',').filter(filterHost).shift();
}

Amplify.configure(awsconfig);

// ===============================|| APP ||============================== //
const App = () => {
  const customization = useSelector((state) => state.customization);
  // console.log(user);
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => {
            setUserInfo(userData);
            console.log(userData);
          });
          break;
        case 'signOut':
          setUserInfo(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });
    getUser().then((userData) => setUserInfo(userData));
  }, []);
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => {
        const midway_s = new String(userData.username);
        const newAlias = midway_s.substr(midway_s.indexOf('_') + 1);
        userData.id = newAlias;
        console.log(userData);
        return userData;
      })
      .catch((err) => {
        console.log('Not signed in');
        console.log(err);
      });
  }

  return (
    <>
      {userInfo ? (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <EventListProvider>
                <OfficeInfoProvider>
                  <SelectedInfoProvider>
                    <LunchInfoProvider>
                      <TalkStatusProvider>
                        <WelcomTopicProvider>
                          <Routes />
                        </WelcomTopicProvider>
                      </TalkStatusProvider>
                    </LunchInfoProvider>
                  </SelectedInfoProvider>
                </OfficeInfoProvider>
              </EventListProvider>
            </NavigationScroll>
          </ThemeProvider>
        </StyledEngineProvider>
      ) : (
        Auth.federatedSignIn()
      )}
    </>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
// require('dotenv').config();

const PARTITION_HEADER = 'X-KG-Partition';
const NEPTUNEAPIGATEWAY_URL = 'https://onarvfzmqk.execute-api.ap-northeast-1.amazonaws.com/prod/';
const fetchOptions = {
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json'
  }
};

async function addAuthHeader(options) {
  // let jwt = Cookies.get("jwt.id")
  const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
  // console.log('jwt', jwt);
  options.headers.Authorization = 'Bearer ' + jwt;
}

async function checkError(response) {
  if (response.status === 401) {
    // User is not logged in, send them to Cognito
    // console.log('Got a 401 from API Gateway, redirecting to Cognito');
    if (window.location.hostname === 'localhost') {
      // console.error('Not redirecting to Cognito from localhost');
    } else {
      window.location = LOGIN_URL;
    }
    return;
  }
  if (response.status >= 400 && response.status < 600) {
    // console.info('response error', response);
    const txt = await response.text();
    throw new Error('Request failed: ' + txt);
  }
}

export async function post(resource, data = {}, partition) {
  const slash = NEPTUNEAPIGATEWAY_URL.endsWith('/') ? '' : '/';
  const url = NEPTUNEAPIGATEWAY_URL + slash + resource;
  const options = Object.assign({}, fetchOptions);
  options.body = JSON.stringify(data);
  // console.log(options.body);
  options.method = 'POST';
  await addAuthHeader(options);
  options.headers[PARTITION_HEADER] = partition;
  const response = await fetch(url, options);
  await checkError(response);
  return response.json();
}

export async function del(resource, id, partition) {
  const slash = NEPTUNEAPIGATEWAY_URL.endsWith('/') ? '' : '/';
  const url = NEPTUNEAPIGATEWAY_URL + slash + resource + '/' + id;
  const options = Object.assign({}, fetchOptions);
  options.method = 'DELETE';
  await addAuthHeader(options);
  options.headers[PARTITION_HEADER] = partition;
  const response = await fetch(url, options);
  await checkError(response);
  return response.json();
}

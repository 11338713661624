import { styled } from '@mui/material/styles';
// import LunchChip from 'ui-component/StatusChip/LunchChip.js'; // hoge
import TalkChip from 'ui-component/StatusChip/TalkChip.js';
import LunchChip from 'ui-component/StatusChip/LunchChip.js';
import AfterWorkChip from 'ui-component/StatusChip/AfterWorkChip.js';

import { Card, Chip, CardContent, Typography, Stack, Button, Avatar, Grid, CircularProgress, Box } from '@mui/material';
import * as React from 'react';
import { getUsertable } from 'graphql/queries';
import { API, Auth } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { FollowButton } from './followButton';
import { listUsertables } from '../graphql/queries';

// styles
const CardStyle = styled(Card)(({ theme }) => ({
  width: 230,
  height: 300,
  minHeight: 200,
  minWidth: 230,
  overflow: 'hidden',
  position: 'relative'
  // background: theme.palette.warning.light,
  // marginTop: '16px',
  // marginBottom: '16px',
}));

export const PhotoWithStatusLocation = (props) => {
  const { inputAlias, others, loading } = props;
  const [onsiteOffice, setOnsiteOffice] = React.useState();
  const [onsiteFloor, setOnsiteFloor] = React.useState();
  const [onsiteSeat, setOnsiteSeat] = React.useState();
  const [statusFreeToTalk, setStatusFreeToTalk] = React.useState();
  const [statusLunch, setStatusLunch] = React.useState();
  const [statusAfterWork, setStatusAfterWork] = React.useState();
  const [initializeFlag, setinitializeFlag] = React.useState(true);
  const [department, setDepartment] = React.useState({});

  const [badgeURL, setBadgeURL] = React.useState();

  React.useEffect(() => {
    if (inputAlias) {
      getInitialInformation(inputAlias);
    }
  }, [inputAlias]);

  const getInitialInformation = async (newAlias) => {
    const userData = await API.graphql({
      query: getUsertable,
      variables: { id: newAlias }
    });
    const { onsite_office, onsite_floor, onsite_seat, status_free_to_talk, status_lunch, status_after_work, department } =
      userData.data.getUsertable;
    if (onsite_office != undefined) {
      setOnsiteOffice(onsite_office);
    }
    if (onsite_floor != undefined) {
      setOnsiteFloor(onsite_floor);
    }
    if (onsite_seat != undefined) {
      setOnsiteSeat(onsite_seat);
    }
    if (status_free_to_talk != undefined) {
      setStatusFreeToTalk(status_free_to_talk);
    }
    if (status_lunch != undefined) {
      setStatusLunch(status_lunch == 1 ? true : false);
    }
    if (status_after_work != undefined) {
      setStatusAfterWork(status_after_work);
    }
    if (department != undefined) {
      setDepartment(department);
    }
    getBadgeFromStorage(newAlias);
    setinitializeFlag(false);
  };

  const getBadgeFromStorage = async (newAlias) => {
    const key = 'find/userdata/badge/hyperbadge_' + newAlias + '.png';
    const signedURL = await Storage.get(key);
    setBadgeURL(signedURL);
  };

  const lunchMessage = statusLunch ? 'Lunch' : '     ';

  return (
    <>
      {(() => {
        if (loading) {
          return (
            <CardStyle>
              <CardContent>
                <CircularProgress color="inherit" size={40} />
              </CardContent>
            </CardStyle>
          );
        } else {
          if (!initializeFlag) {
            return (
              <Box sx={{ p: 1, m: 1 }}>
                <CardStyle>
                  <CardContent sx={{ bgcolor: '#f5f5f5' }}>
                    <Stack spacing={1}>
                      <Link to={`/my-page/${inputAlias}`} key={inputAlias}>
                        <Typography variant="h4" align="center">
                          {inputAlias}
                        </Typography>
                      </Link>
                      <FollowButton others={others} othersAlias={inputAlias} />
                      <TalkChip status_flag={statusFreeToTalk} />
                      <Grid container alignItems="center" justify="center">
                        <Grid item xs={6}>
                          <LunchChip status_flag={statusLunch} />
                        </Grid>
                        <Grid item xs={6}>
                          <AfterWorkChip status={statusAfterWork} />
                        </Grid>
                      </Grid>
                      {/* <Typography variant="body2"></Typography> */}
                      <Grid container alignItems="center" justify="center">
                        <Grid item xs={2}></Grid>
                        <Link to={`/my-page/${inputAlias}`} key={inputAlias}>
                          <Grid item xs={8}>
                            <Avatar alt="icon Image" src={badgeURL} sx={{ width: 120, height: 120 }} />
                          </Grid>
                        </Link>
                        {/* <Typography>所属 : {department}</Typography> */}

                        <Grid item xs={2}></Grid>
                      </Grid>
                      {/* <img src={SamplgeBadge} alt="Badge" height="200px" /> */}
                      {/* <Button size="small" variant="contained"> */}
                      {(() => {
                        if (
                          onsiteOffice == undefined ||
                          onsiteFloor == undefined ||
                          onsiteSeat == undefined ||
                          onsiteOffice == '' ||
                          onsiteFloor == '' ||
                          onsiteSeat == ''
                        ) {
                          return <Chip size="small" label="座席未登録" />;
                        } else {
                          return (
                            <Button
                              variant="contained"
                              style={{ maxWidth: '180px', maxHeight: '20px', minWidth: '180px', minHeight: '20px' }}
                              component={Link}
                              to={`/find-page/${inputAlias}_${onsiteOffice}_${onsiteFloor}_${onsiteSeat}`}
                            >
                              <Typography variant="button">
                                {onsiteOffice} {onsiteFloor} {onsiteSeat}
                              </Typography>
                            </Button>
                          );
                        }
                      })()}
                      {/* </Button>ss */}
                    </Stack>
                  </CardContent>
                </CardStyle>
              </Box>
            );
          } else {
            return (
              <CardStyle>
                <CardContent></CardContent>
              </CardStyle>
            );
          }
        }
      })()}
    </>
  );
};

// For test

const userstatus = {
  omaeryo: {
    onsite_office: 'HND17',
    onsite_floor: '22F',
    onsite_seat: 'W109',
    status_free_to_talk: true,
    status_lunch: true,
    status_after_work: 'Drink'
  },
  thakairo: {
    onsite_office: 'HND10',
    onsite_floor: '17F',
    onsite_seat: 'E55',
    status_free_to_talk: false,
    status_lunch: false,
    status_after_work: 'Activities'
  }
};

import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';
import { FullscreenExit, Launch } from '@mui/icons-material';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone';

const LunchChip = (props) => {
  const { status_flag } = props; //Boolean hoge
  if (status_flag) {
    return <Chip icon={<RestaurantIcon />} color="success" size="small" label="Lunch" style={{ maxWidth: '90px', minWidth: '90px' }} />;
  } else {
    return (
      <Chip
        icon={<DoNotDisturbOnTwoToneIcon />}
        label="No Lunch"
        size="small"
        variant="outlined"
        style={{ maxWidth: '90px', minWidth: '90px' }}
      />
    );
  }
};

export default LunchChip;

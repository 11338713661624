// トップページの { [HND17] [22F] [W129] }というステータスを表示・変更することができるドロップダウンメニュー
// 入力候補はjsonから引っ張ってくる→入力情報はそのliasのuser_table.(onsite_office, onsite_floor, onsite_seat)に書き込む
// 表示する情報はUser_tableを参照するが、値が含まれていない場合は何も表示しない or ドロップダウンメニューの部分を赤く表示する
// 参考サイト : https://mui.com/material-ui/react-autocomplete/

// 導入方法
//   /src/layout/MainLayout/Header/index.js に変更を加える.
//
//          ;
//   Header 関数の return 内に
//      <OnsiteStatus />
//   を 追加
// edit by itotsum
import * as React from 'react';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete, Stack, Grid, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import Office from '../../../../resources/office.json';
import Floor from '../../../../resources/floor.json';
import Seat from '../../../../resources/seat.json';
import { ConsoleLogger } from '@aws-amplify/core';

import { updateUserdata } from '../../../../graphql/mutations';
import { getUsertable } from '../../../../graphql/queries';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { OfficeInfoContext } from 'components/provider/OfficeInfoProvider';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

const user_tabledata = {
  user1: {
    onsite_office: 'HND17',
    onsite_floor: '22F',
    onsite_seat: '22.101'
  }
};

const OnsiteStatus = () => {
  const blank = '';
  // const blank = undefined;
  const [alias, setAlias] = React.useState();

  // const [onsiteOffice, setOffice] = React.useState(blank);
  // const [onsiteFloor, setFloor] = React.useState(blank);
  // const [onsiteSeat, setSeat] = React.useState(blank);
  const { onsiteOffice, setOffice, onsiteFloor, setFloor, onsiteSeat, setSeat } = React.useContext(OfficeInfoContext);
  const [onsiteFloorOptions, setFloorOptions] = React.useState([]);
  const [onsiteSeatOptions, setSeatOptions] = React.useState([]);
  const [initializeFlag, setinitializeFlag] = React.useState(true);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then((user) => {
      const midway_s = new String(user.username);
      const newAlias = midway_s.substr(midway_s.indexOf('_') + 1);
      setAlias(newAlias);
      getInitialLocation(newAlias);
      setinitializeFlag(false);
    });
  }, []);

  React.useEffect(() => {
    if (onsiteOffice != blank) {
      setFloorOptions(Floor[onsiteOffice]);
    }
    if (onsiteOffice != blank && onsiteFloor != blank) {
      setFloorOptions(Floor[onsiteOffice]);
      setSeatOptions(Seat[onsiteOffice][onsiteFloor]);
    }
  }, [onsiteOffice]);

  React.useEffect(() => {
    if (onsiteOffice != blank && onsiteFloor != blank) {
      setSeatOptions(Seat[onsiteOffice][onsiteFloor]);
    }
  }, [onsiteFloor]);

  const updateLocationsfunc = async (newInputFloor) => {
    const res = await API.graphql({
      query: updateUserdata,
      variables: {
        input: {
          id: alias,
          onsite_office: onsiteOffice,
          onsite_floor: onsiteFloor,
          onsite_office_floor: onsiteOffice + onsiteFloor,
          onsite_seat: newInputFloor
        }
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }); // updated
  };

  const getInitialLocation = async (newAlias) => {
    const userData = await API.graphql({
      query: getUsertable,
      variables: { id: newAlias }
    });
    const { onsite_office, onsite_floor, onsite_seat } = userData.data.getUsertable;
    if (!(onsite_office == undefined || onsite_floor == undefined || onsite_seat == undefined)) {
      setOffice(onsite_office);
      setFloor(onsite_floor);
      setSeat(onsite_seat);
    }
  };

  return (
    <Card variant="outlined">
      <Stack spacing={0}>
        <Grid container spacing={0} alignItems="center" justify="center">
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            座席登録
          </Grid>
        </Grid>
        <Stack direction="row" spacing={0.8}>
          {/* OFFICE */}
          <Autocomplete
            value={onsiteOffice}
            disablePortal
            disableClearable
            id="header-onsite-office"
            options={Office.offices} // 表示するリスト
            onInputChange={(event, newOnsiteOffice) => {
              setOffice(newOnsiteOffice);
              setFloor(blank);
              setSeat(blank);
            }}
            sx={{
              width: 120,
              '.MuiInputBase-input': {
                height: '10px'
              }
            }}
            renderInput={(params) => (
              <TextField
                error={onsiteOffice == blank || onsiteOffice == undefined ? true : false}
                {...params}
                label="Office"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {initializeFlag ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />

          {/* FLOOR */}
          <Autocomplete
            value={onsiteFloor}
            disablePortal
            disableClearable
            id="header-onsite-floor"
            options={onsiteFloorOptions} //表示するリスト
            onInputChange={(event, newOnsiteFloor) => {
              setFloor(newOnsiteFloor);
              setSeat(blank);
            }}
            sx={{
              width: 120,
              '.MuiInputBase-input': {
                height: '10px'
              }
            }}
            renderInput={(params) => (
              <TextField
                error={onsiteFloor == blank || onsiteFloor == undefined ? true : false}
                {...params}
                label="Floor"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {initializeFlag ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />

          {/* SEAT */}
          <Autocomplete
            value={onsiteSeat}
            disablePortal
            disableClearable
            id="header-onsite-seat"
            options={onsiteSeatOptions} //表示するリスト
            onInputChange={(event, newOnsiteSeat) => {
              setSeat(newOnsiteSeat);
              if (newOnsiteSeat != blank) {
                updateLocationsfunc(newOnsiteSeat);
              }
            }}
            sx={{
              width: 120,
              '.MuiInputBase-input': {
                height: '10px'
              }
            }}
            renderInput={(params) => (
              <TextField
                error={onsiteSeat == blank || onsiteSeat == undefined ? true : false}
                {...params}
                label="Seat"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {initializeFlag ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default OnsiteStatus;

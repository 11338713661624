import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';
import { FullscreenExit, Launch } from '@mui/icons-material';
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const AfterWorkChip = (props) => {
  const { status } = props; //String hoge
  {
  }

  switch (status) {
    case 'None':
      return <Chip icon={<DoNotDisturbOnTwoToneIcon />} size="small" label="None" style={{ maxWidth: '90px', minWidth: '90px' }} />;
      break;
    case 'Drink':
      return <Chip icon={<SportsBarIcon />} color="success" size="small" label="Drink" style={{ maxWidth: '90px', minWidth: '90px' }} />;
      break;
    case 'Activities':
      return (
        <Chip icon={<DirectionsRunIcon />} color="success" size="small" label="Activities" style={{ maxWidth: '90px', minWidth: '90px' }} />
      );
      break;
    case 'Food only':
      return (
        <Chip icon={<LocalDiningIcon />} color="success" size="small" label="Food only" style={{ maxWidth: '90px', minWidth: '90px' }} />
      );
      break;
  }
};

export default AfterWorkChip;

// 2022.08.29
// Headerのコンフリクト回避用のファイル
import * as React from 'react';
import { styled, Stack } from '@mui/system';
import { Autocomplete, ToggleButton, TextField, ToggleButtonGroup, Button, IconButton, Chip, Card, Grid, Typography } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { LunchInfoContext } from 'components/provider/LunchInfoProvider';

import { updateUserdata } from '../graphql/mutations';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { getUsertable } from 'graphql/queries';

export const LunchAfterWork = () => {
  const [alias, setAlias] = React.useState();
  const [lunch, setLunch] = React.useState('lunch');
  const { lunchSelected, setLunchSelected } = React.useContext(LunchInfoContext);
  // const [lunchSelected, setLunchSelected] = React.useState(true);
  const [afterwork, setAfterWork] = React.useState('None');
  const [initializeFlag, setinitializeFlag] = React.useState(true);

  const handleChangeLunch = (flag) => {
    if (flag == true) {
      setLunchSelected(true);
    } else {
      setLunchSelected(false);
    }
    createLunchfunc(flag ? 1 : 0);
  };

  const handleChangeAfterWork = (event, newInputValue) => {
    if (newInputValue == null) {
      newInputValue = afterwork;
    }
    setAfterWork(newInputValue);

    createAfterWorkfunc(newInputValue);
  };

  const createLunchfunc = async (status) => {
    const res = await API.graphql({
      query: updateUserdata,
      variables: {
        input: {
          id: alias,
          status_lunch: status
        }
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }); // updated
  };

  const createAfterWorkfunc = async (newInputValue) => {
    const res = await API.graphql({
      query: updateUserdata,
      variables: {
        input: {
          id: alias,
          status_after_work: newInputValue
        }
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    }); // updated
  };

  React.useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then((user) => {
      const midway_s = new String(user.username);
      const newAlias = midway_s.substr(midway_s.indexOf('_') + 1);
      setAlias(newAlias);
      getInitialLunchAfterWork(newAlias);
      setinitializeFlag(false);
    });
  }, []);

  const getInitialLunchAfterWork = async (newAlias) => {
    const userData = await API.graphql({
      query: getUsertable,
      variables: { id: newAlias }
    });
    const { status_lunch, status_after_work } = userData.data.getUsertable;
    if (status_lunch != undefined) {
      setLunch(status_lunch == 1 ? 'freetotalk' : null);
      setLunchSelected(status_lunch == 1 ? true : false);
    }
    if (status_after_work != undefined) {
      setAfterWork(status_after_work);
    }
  };

  const afterworklist = ['None', 'Drink', 'Food only', 'Activities'];
  const afterworklist_icons = [
    { label: 'None', icon: <SportsBarIcon /> },
    { label: 'Drink', icon: <SportsBarIcon /> },
    { label: 'Food only', icon: <SportsBarIcon /> },
    { label: 'Activities', icon: <SportsBarIcon /> }
  ];

  return (
    <Stack spacing={0.5} direction="row" justifyContent="center" alignItems="center">
      {/* // <React.Fragment> */}
      {(() => {
        if (lunchSelected) {
          return (
            <Card variant="outlined">
              <Stack spacing={0}>
                <Grid container spacing={0} alignItems="center" justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={11}>
                    誰かと昼食べる？
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={initializeFlag}
                  style={{ maxWidth: '130px', maxHeight: '40px', minWidth: '130px', minHeight: '40px' }}
                  onClick={() => {
                    handleChangeLunch(false);
                  }}
                >
                  <RestaurantIcon />
                  Lunch
                </Button>
              </Stack>
            </Card>
          );
        } else {
          return (
            <Card variant="outlined">
              <Stack spacing={0}>
                <Grid container spacing={0} alignItems="center" justify="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={11}>
                    誰かと昼食べる？
                  </Grid>
                </Grid>
                <Button
                  // variant="outlined"
                  color="inherit"
                  size="small"
                  disabled={initializeFlag}
                  style={{ maxWidth: '130px', maxHeight: '40px', minWidth: '130px', minHeight: '40px' }}
                  onClick={() => {
                    handleChangeLunch(true);
                  }}
                >
                  <DoNotDisturbOnTwoToneIcon />
                  No Lunch
                </Button>
              </Stack>
            </Card>
          );
        }
      })()}
      <Card variant="outlined">
        <Stack spacing={0}>
          <Grid container spacing={0} alignItems="center" justify="center">
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              仕事後何やる？
              {/* <Typography variant="caption">After work</Typography> */}
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          <ToggleButtonGroup
            size="small"
            color="success"
            exclusive
            value={afterwork}
            aria-label="text alignment"
            disabled={initializeFlag}
            onChange={handleChangeAfterWork}
            style={{ maxWidth: '330px', maxHeight: '40px', minWidth: '330px', minHeight: '40px' }}
          >
            <ToggleButton size="small" value="None" aria-label="centered">
              <DoNotDisturbOnTwoToneIcon /> None
            </ToggleButton>
            <ToggleButton value="Drink" aria-label="Drink">
              <SportsBarIcon /> Drink
            </ToggleButton>
            <ToggleButton value="Food only" aria-label="Food only">
              <LocalDiningIcon /> Food only
            </ToggleButton>
            <ToggleButton value="Activities" aria-label="Activities">
              <DirectionsRunIcon /> Activities
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Card>
      {/* </React.Fragment> */}
    </Stack>
  );
};

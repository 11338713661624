import { createContext, useState } from 'react';

export const SelectedInfoContext = createContext({});

export const SelectedInfoProvider = (props) => {
    const { children } = props;

    const [allSelected, updateAllStated] = useState('all');

    return <SelectedInfoContext.Provider value={{ allSelected, updateAllStated }}>{children}</SelectedInfoContext.Provider>;
};

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing`
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// sgswyuta
// Add "Find, event, network" page using "utils"
const FindPage = Loadable(lazy(() => import('views/find-page')));
const EventPage = Loadable(lazy(() => import('views/event-page')));
const NetworkPage = Loadable(lazy(() => import('views/network-page')));
const Post = Loadable(lazy(() => import('views/event-page/Post')));
const Posts = Loadable(lazy(() => import('views/event-page/Posts')));
const MyPage = Loadable(lazy(() => import('views/mypage')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    // sgswyuta add find, event and network page
    {
      path: 'find-page',
      element: <FindPage />
    },
    {
      path: 'find-page',
      children: [
        {
          path: ':id',
          element: <FindPage />
        }
      ]
    },
    {
      path: 'event-page',
      element: <EventPage />
    },
    {
      path: 'event-page',
      children: [
        {
          path: ':id',
          element: <Post />
        }
      ]
    },
    {
      path: 'network-page',
      element: <NetworkPage />
    },
    {
      path: 'my-page',
      element: <MyPage othersMyPage={false} />
    },
    {
      path: 'my-page',
      children: [
        {
          path: ':id',
          element: <MyPage />
        }
      ]
    }
  ]
};

export default MainRoutes;
